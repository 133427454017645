


































































































import { Vue, Prop, Component } from 'vue-property-decorator'
import PBeatTable from '@elitepage/components/PBeatTable.vue'
import PLicenseOptionBox from '@elitepage/components/PLicenseOptionBox.vue'
import PSoundkitBox from '@elitepage/components/PSoundkitBox.vue'
import PServiceBox from '@elitepage/components/PServiceBox.vue'

import { BeatFilterType, fetchBeats } from '~/api/beats'
import { fetchProducerLicenseOptions } from '@/api/producers'
import { ElitepageStore } from '@elitepage/store/elitepage'
import { sendEmailContactElitePage } from '@elitepage/api/email'
import { fetchSoundKits } from '@elitepage/api/sound-kits'
import { fetchServices } from '@elitepage/api/services'
import { CartStore } from '~/store/cart'
import { fetchElitePageAnalytics } from '@elitepage/api/elitepage'
import PMailingListSignup from '@elitepage/components/PMailingListSignup.vue'

@Component({ components: { PBeatTable, PLicenseOptionBox, PSoundkitBox, PServiceBox, PMailingListSignup } })
export default class HomePage extends Vue {
    @Prop({ type: String }) producerSlug!: IProducer['slug']

    beats: IBeat[] = []
    soundkits: ISoundKit[] = []
    services: IService[] = []
    licenseOptions: ILicenseOptionBeat[] = null
    contactForm = {
        producer_email: '',
        name: '',
        email: '',
        message: '',
        messageSent: false,
        error: ''
    }
    analytics: { beatPlays: number; pageViews: number } = { beatPlays: 0, pageViews: 0 }

    @ElitepageStore.State('elitepage') elitepage: IElitepage
    @ElitepageStore.Getter('bodySectionsAsMap') bodySectionsAsMap: Record<string, number>
    @ElitepageStore.State('producer') producer: IProducer

    get sameDealForAllLicenses() {
        if (!this.licenseOptions) return null
        // remove "Exclusive" licenses from the array. If they are all exclusive, return
        const licensesWithoutExclusives = this.licenseOptions.filter(item => item.is_exclusive === false)
        if (licensesWithoutExclusives.length === 0) return null
        // check if the remaining licenses have same deal_buy & deal_get
        const dealBuy = licensesWithoutExclusives.every(v => v.deal_buy === licensesWithoutExclusives[0].deal_buy)
        const dealGet = licensesWithoutExclusives.every(v => v.deal_get === licensesWithoutExclusives[0].deal_get)
        // and they do and the deal get is bigger or equal to 1, return obj of deal_buy and deal_get
        if (dealBuy && dealGet && licensesWithoutExclusives[0].deal_get >= 1) {
            return {
                dealBuy: licensesWithoutExclusives[0].deal_buy,
                dealGet: licensesWithoutExclusives[0].deal_get
            }
        }
        return null
    }

    get contactFormIsEmpty(): boolean {
        return this.contactForm.name.length === 0 || this.contactForm.email.length === 0 || this.contactForm.message.length === 0
    }

    async fetchLicenseOptions() {
        const licenseOptions = await fetchProducerLicenseOptions(this.producerSlug)
        licenseOptions.sort((a, b) => (a.value !== b.value ? a.value - b.value : a.price - b.price))
        this.licenseOptions = licenseOptions
    }

    async sendInquiryEmail() {
        if (this.contactFormIsEmpty) this.contactForm.error = 'Please fill out the form above to submit your inquiry'
        else {
            this.contactForm.producer_email = this.producer.email
            try {
                await sendEmailContactElitePage(this.contactForm)
                this.contactForm.messageSent = true
            } catch (error) {
                this.contactForm.error = `Error submitting, please contact ${this.producer.email}`
            }
        }
    }

    async mounted() {
        this.analytics = await fetchElitePageAnalytics(this.producer.id)
        this.beats = await fetchBeats({ filterType: BeatFilterType.Producer, slug: this.producerSlug, limit: 10 })
        await this.fetchLicenseOptions()
        this.soundkits = await fetchSoundKits(this.producer.id, 4)
        this.services = await fetchServices(this.producer.id, 4)
    }
}
