














import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { ElitepageStore } from '@elitepage/store/elitepage'

@Component
export default class PServiceBox extends Vue {
    @Prop({ type: Object }) service: IService

    @ElitepageStore.State('elitepage') elitepage: any
}
