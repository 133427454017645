import { post } from '~/services/http-requests'
import { _BS_EMAIL } from '~/api/email'

/*
 **** GET ****
 */
function sendEmailContactElitePage(contactForm: { name: string; email: string; message: string; producer_email: string }): Promise<any> {
    return post({ fullUrl: `${_BS_EMAIL}/send_email_contact_elite_page/`, dispatchParams: contactForm, isFreeRequest: true })
}

export { sendEmailContactElitePage }
