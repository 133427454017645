












import Vue from 'vue'
import { Prop, Component, Mixins } from 'vue-property-decorator'
import { ElitepageStore } from '@elitepage/store/elitepage'
import { Colors } from '@elitepage/mixins/colors'

@Component
export default class PSoundkitBox extends Mixins(Colors) {
    @Prop({ type: Object }) soundkit: ISoundKit

    @ElitepageStore.State('elitepage') elitepage: IElitepage
}
