


























import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { ElitepageStore } from '@elitepage/store/elitepage'

@Component
export default class PLicenseOptionBox extends Vue {
    @Prop({ type: Object }) license: ILicenseOption

    @ElitepageStore.State('elitepage') elitepage: IElitepage

    get saleUnitsConverted() {
        if (this.license.sale_units === 0) return 'Unlimited'
        return this.license.sale_units.toLocaleString()
    }

    get streamUnitsConverted() {
        if (this.license.stream_units === 0) return 'Unlimited'
        return this.license.stream_units.toLocaleString()
    }
}
