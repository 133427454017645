import { post } from '~/services/http-requests'

export const _BS_EMAIL = 'sendgrid'

/*
 **** GET ****
 */
function sendEmailPasswordChanged(): Promise<any> {
    return post({ fullUrl: `${_BS_EMAIL}/send_email_password_changed_successful/`, isFreeRequest: true })
}

function sendEmailContactSupport(contactForm: { name: string; email: string; message: string }): Promise<any> {
    return post({ fullUrl: `${_BS_EMAIL}/send_email_contact_support/`, dispatchParams: contactForm, isFreeRequest: true })
}

export { sendEmailPasswordChanged, sendEmailContactSupport }
